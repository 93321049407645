import { Item } from "../types/Item";
import { Slide } from "../types/Slide";
import { FileTemplate } from "../types/fileTemplate";
import Profile from "../types/profile";
import { Project } from "../types/project";
import { getApp, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
	connectFirestoreEmulator,
	Firestore,
	getFirestore,
	initializeFirestore,
} from "firebase/firestore";
import {
	HttpsCallable,
	getFunctions,
	httpsCallable,
	httpsCallableFromURL,
} from "firebase/functions";
import { getRemoteConfig } from "firebase/remote-config";
import {
	connectStorageEmulator,
	FirebaseStorage,
	getStorage,
} from "firebase/storage";

const urlParams = new URLSearchParams(window.location.search);
const EMULATORS = urlParams.get("emulators") === "true";

const firebaseConfigs = {
	test: {
		apiKey: "AIzaSyA2XODOcys2xMm2IFn1CFDsxn5V8noPkd8",
		authDomain: "softcapdemo.firebaseapp.com",
		projectId: "softcapdemo",
		storageBucket: "softcapdemo.appspot.com",
		messagingSenderId: "403344680165",
		appId: "1:403344680165:web:32a7016fea32465898600c",
	},
	prod: {
		apiKey: "AIzaSyD9DqPs-b511jY-05BAtDOdIIxF3VOifBs",
		authDomain: "sc-dm-prod.firebaseapp.com",
		projectId: "sc-dm-prod",
		storageBucket: "sc-dm-prod.appspot.com",
		messagingSenderId: "17324244046",
		appId: "1:17324244046:web:966e1d6508c8df841ad71f",
		measurementId: "G-WMXDWD8HZC",
	},
};

const firebaseConfig = [
	"localhost:3000",
	"demo.softcap.no",
	"softcapdemo.web.app",
].includes(window.location.host)
	? firebaseConfigs.test
	: firebaseConfigs.prod;

const app = initializeApp(firebaseConfig);
const auth = getAuth();
const remoteConfig = getRemoteConfig(app);

if (EMULATORS) {
	connectAuthEmulator(auth, "http://localhost:9099");
	//connectStorageEmulator(storage, "127.0.0.1", 9199);
	//connectFirestoreEmulator(getFirestore(), "localhost", 8080); // Adjust port if needed
}

let db: Firestore; // = getFirestore();
let storage: FirebaseStorage;

const setDb = (dbName?: string) => {
	console.log("Setting db name to", dbName);
	if (EMULATORS) {
		connectFirestoreEmulator(
			dbName ? getFirestore(dbName) : getFirestore(),
			"localhost",
			8080
		);
	}
	db = dbName ? getFirestore(dbName) : getFirestore();
};

const setStorage = (bucketName?: string) => {
	console.log("Setting storage name to", bucketName);

	storage = bucketName
		? getStorage(app, firebaseConfig.projectId + "-" + bucketName)
		: getStorage();
	if (EMULATORS) {
		connectStorageEmulator(storage, "127.0.0.1", 9199);
	}
};

//const db = getFirestore();
//const storage = getStorage();
//const functions = getFunctions(getApp());
const functions_eur = getFunctions(getApp(), "europe-west1");

var pptlib: HttpsCallable;
var validateAuth: HttpsCallable<{ token: string }, { token: string }>;
/*var updateSlideTags: HttpsCallable<
    {
        slideId: string;
        tags: { tagId: string; position?: number; op: "update" | "delete" }[];
    },
    { message: string }
>; */

var updateTagAcls: HttpsCallable<
	{
		tagId: string;
		viewers: string[];
		groupViewers: string[];
		groupOwners: string[];
		owners: string[];
	},
	{ message: string }
>;

var addSlidesToCollection: HttpsCallable<
	{
		accessToken: string;
		collectionId: string;
		slides: string;
		templateId: string;
		outbox: true;
		filename: string;
		emulator?: boolean;
	},
	{ message: string }
>;
var sendSlidesTo: HttpsCallable<
	{ recipients: Profile[]; slides: Slide[]; message?: string },
	{ message: string }
>;

var addWatermarkToPdf: HttpsCallable<
	{
		item: Item;
		text: string;
		opacity: number;
		color: { r: number; g: number; b: number };
		accessToken: string;
		EMULATORS?: boolean;
	},
	{ fileContents: string }
>;
var getAccessToken: HttpsCallable<
	{ authorization: string; EMULATORS?: boolean },
	string
>;
var createProjectManagedFolder: HttpsCallable<{
	folderName: string;
	accessToken: string;
	EMULATORS: boolean;
}>;

var copyFileTemplateToProject: HttpsCallable<{
	fileTemplate: FileTemplate;
	accessToken: string;
	projectId: string;
	EMULATORS: boolean;
}>;

var updateProfilePhoto: HttpsCallable<{
	accessToken: string;
}>;

var sendProjectToApproval: HttpsCallable<{
	accessToken: string;
	project: Project;
	fileMap: any;
	EMULATORS: boolean;
}>;
var generateTemplatePreview: HttpsCallable;
var generatePreview: HttpsCallable<{
	projectId: string;
	itemId: string;
	driveId: string;
	accessToken: string;
	emulator?: boolean;
}>;
var createFileFromTemplate: HttpsCallable;
var uploadTemplate: HttpsCallable;
var createFolderForProject: HttpsCallable<{
	project: Project;
	accessToken: string;
	EMULATORS: boolean;
}>;

var processImageGetPHash: HttpsCallable<
	{ imageAsBase64: string },
	{ message: string; pHash: string }
>;

var updateGroupMembers: HttpsCallable<
	{
		groupId: string;
		members: {
			op: "ADD" | "REMOVE";
			role?: "member" | "owner";
			uid: string;
		}[];
	},
	{ message: string }
>;

var updateCollectionsForSlide: HttpsCallable<
	{
		slideId: string;
		collections: { op: "ADD" | "REMOVE"; collectionId: string }[];
	},
	{ message: string }
>;
var branchSlideIntoCollections: HttpsCallable<
	{ slideId: string; branchIntoCollectionIds: string[] },
	{ message: string; newSlideId: string }
>;
var replaceSlideInCollection: HttpsCallable<
	{ slideId: string; collectionIds: string[] },
	{ message: string; newSlideId: string }
>;

// Defaults to single-tab persistence if no tab manager is specified.
//initializeFirestore(app, {localCache: persistentLocalCache(/*settings*/{})});

// Use this function and some help fro chatgpt to create a local instance of https callable
// function httpsCallable<RequestData = unknown, ResponseData = unknown>(functionsInstance: Functions, name: string, options?: HttpsCallableOptions): HttpsCallable<RequestData, ResponseData>;
if (EMULATORS && window.location.host === "localhost:3000") {
	console.log("**** Using LOCAL EUMULATORS ****");

	const localhostPrefixEur = "http://localhost:5001/softcapdemo/europe-west1";
	//connectAuthEmulator(auth, "http://localhost:9099");
	//connectFirestoreEmulator(db, 'localhost', 8080);
	//connectFunctionsEmulator(functions_eur, "localhost", 5001);

	createProjectManagedFolder = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/createProjectManagedFolder"
	);

	updateProfilePhoto = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/updateProfilePhoto"
	);

	validateAuth = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/validateAuth"
	);
	getAccessToken = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/getAccessToken"
	);

	sendProjectToApproval = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/sendProjectToApproval"
	);

	generateTemplatePreview = httpsCallableFromURL(
		functions_eur,
		"generateTemplatePreview"
	);
	createFileFromTemplate = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/createFileFromTemplate"
	);
	generatePreview = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/generatePreview"
	);
	createFolderForProject = httpsCallable(
		functions_eur,
		localhostPrefixEur + "/createFolderForProject"
	);

	sendSlidesTo = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/sendSlidesTo"
	);

	addSlidesToCollection = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/addSlidesToCollection"
	);

	createFolderForProject = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/createFolderForProject"
	);

	pptlib = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/pptgenerator2"
	);

	addWatermarkToPdf = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/addWatermarkToPdf"
	);

	updateTagAcls = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/updateTagAcls"
	);

	copyFileTemplateToProject = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/"
	);

	uploadTemplate = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/uploadTemplate"
	);

	processImageGetPHash = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/processImageGetPHash"
	);

	updateCollectionsForSlide = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/updateCollectionsForSlide"
	);

	replaceSlideInCollection = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/replaceSlideInCollection"
	);

	updateGroupMembers = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/updateGroupMembers"
	);

	branchSlideIntoCollections = httpsCallableFromURL(
		functions_eur,
		localhostPrefixEur + "/branchSlideIntoCollections"
	);
} else {
	validateAuth = httpsCallable(functions_eur, "validateAuth");
	createProjectManagedFolder = httpsCallable(
		functions_eur,
		"createProjectManagedFolder"
	);
	sendProjectToApproval = httpsCallable(
		functions_eur,
		"sendProjectToApproval"
	);
	updateProfilePhoto = httpsCallable(functions_eur, "updateProfilePhoto");
	updateTagAcls = httpsCallable(functions_eur, "updateTagAcls");
	getAccessToken = httpsCallable(functions_eur, "getAccessToken");
	sendSlidesTo = httpsCallable(functions_eur, "sendSlidesTo");
	addSlidesToCollection = httpsCallable(
		functions_eur,
		"addSlidesToCollection"
	);
	generateTemplatePreview = httpsCallable(
		functions_eur,
		"generateTemplatePreview"
	);
	createFileFromTemplate = httpsCallable(
		functions_eur,
		"createFileFromTemplate"
	);
	uploadTemplate = httpsCallable(functions_eur, "uploadTemplate");
	generatePreview = httpsCallable(functions_eur, "generatePreview");
	pptlib = httpsCallable(functions_eur, "pptgenerator2");
	addWatermarkToPdf = httpsCallable(functions_eur, "addWatermarkToPdf");
	copyFileTemplateToProject = httpsCallable(
		functions_eur,
		"copyFileTemplateToProject"
	);
	createFolderForProject = httpsCallable(
		functions_eur,
		"createFolderForProject"
	);
	processImageGetPHash = httpsCallable(functions_eur, "processImageGetPHash");

	updateCollectionsForSlide = httpsCallable(
		functions_eur,
		"updateCollectionsForSlide"
	);

	updateGroupMembers = httpsCallable(functions_eur, "updateGroupMembers");

	replaceSlideInCollection = httpsCallable(
		functions_eur,
		"replaceSlideInCollection"
	);

	branchSlideIntoCollections = httpsCallable(
		functions_eur,
		"branchSlideIntoCollections"
	);
}

/*
const getMessagingToken = async () => {
    return getToken(messaging, {vapidKey: "BNcepPj1Y7bj_Kb2bu0Le2r6ROf1b1bizG2h1NmTriX14iBdzXDoSCcxnlcxCylK6i4nb6FhVgnJFxIXz5Fqris"});
}*/

export {
	EMULATORS,
	setDb,
	setStorage,
	db,
	addSlidesToCollection,
	addWatermarkToPdf,
	app,
	auth,
	remoteConfig,
	copyFileTemplateToProject,
	createFileFromTemplate,
	createFolderForProject,
	createProjectManagedFolder,
	generatePreview,
	generateTemplatePreview,
	getAccessToken,
	pptlib,
	sendProjectToApproval,
	sendSlidesTo,
	storage,
	updateProfilePhoto,
	updateCollectionsForSlide,
	updateTagAcls,
	uploadTemplate,
	validateAuth,
	updateGroupMembers,
	replaceSlideInCollection,
	branchSlideIntoCollections,
	processImageGetPHash,
};
